import React from "react"
import PropTypes from "prop-types"

const Column = ({ classes, children }) => {
    
    classes.unshift('column')
    
    return (
        <div className={classes.join(' ')}>
            {children}
        </div>
    )
}

Column.propTypes = {
    classes: PropTypes.array,
}

Column.defaultProps = {
    classes: []
}

export default Column
