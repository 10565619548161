import React from "react"
import { Link } from "gatsby"

export default ({ to, external, fake, className, children }) => {
    if (fake) {
        return (
            <span className={`link ${ className || '' }`}>{children}</span>
        )
    }
    
    if (external) {
        return (
            <a className={className} href={to} target="_blank" rel="noopener noreferrer">{children}</a>
        )
    }
    
    return (
        <Link className={className} to={to} activeClassName="active">{children}</Link>
    )
}
