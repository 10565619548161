import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Column from "../column"
import BrandName from "../brand-name"
import Logo from "../../images/footer-logo.inline.svg"
import TwitterLogo from "../../images/twitter-logo.inline.svg"

const Header = ({ siteTitle }) => (
    <footer className="sitefooter">
        <div className="container sitefooter__container">
            <Column classes={['column--logo']}>
                <Link to="/" title="Zur Startseite" className="sitefooter__logo">
                    <Logo />
                    <span className="sitefooter__logo__label">Zur Startseite</span>
                </Link>
            </Column>
            <Column classes={['column--nav sitefooter__nav']}>
                <nav>
                    <ul>
                        <li>Copyright &copy; {new Date().getFullYear()} <BrandName /></li>
                        <li><Link to="/datenschutz/">Datenschutz</Link></li>
                        <li><Link to="/impressum/">Impressum</Link></li>
                    </ul>
                    <p><span className="has-icon has-icon--privacy">Keine Cookies, kein Tracking – für mehr Privatsphäre.</span></p>
                </nav>
            </Column>
            <Column classes={['column--aside sitefooter__aside']}>
                <a href="https://twitter.com/ehex_" rel="noopener noreferrer" target="_blank" className="has-icon twitter">
                    <TwitterLogo />
                    <span className="label">Support bei Twitter</span>
                </a>
            </Column>
        </div>
    </footer>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
