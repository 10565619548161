/**
* Layout component that queries for data
* with Gatsby's StaticQuery component
*
* See: https://www.gatsbyjs.org/docs/static-query/
*/

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "../global/header"
import Footer from "../global/footer"
import "../../sass/app.scss"

if (typeof window !== "undefined") {
    require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ className, children }) => (
    <StaticQuery
    query={graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
        `}
        render={data => (
            <>
                <div className={`wrapper ${ className || '' }`}>
                    <Header siteTitle={data.site.siteMetadata.title} />
                    <main>
                        {children}
                    </main>
                </div>
                <Footer />
                <span style={{ display: 'none' }} dangerouslySetInnerHTML={{ __html: `<!-- ${new Date().toString()} -->` }} />
            </>
        )}
        />
    )
    
Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
