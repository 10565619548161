import { default as Link } from "../link"
import PropTypes from "prop-types"
import React from "react"

import Logo from "../../images/logo.inline.svg"

const Header = ({ siteTitle }) => (
    <header className="siteheader">
        <div className="container siteheader__container container--full">
            <div className="siteheader__cta">
                <Link to="/bestellen/" className="button">
                    <span className="label">Jetzt bestellen</span>
                </Link>
            </div>
            <Link to="/" className="siteheader__logo">
                <Logo />
                <span className="siteheader__logo__label">{siteTitle}</span>
            </Link>
            <nav className="siteheader__nav">
                <ul>
                    <li><Link to="/dvo/">Dienstleister vor Ort</Link></li>
                    <li><Link to="/praxen/">Für Ärzte &amp; Kliniken</Link></li>
                    <li><Link to="/support/">Support</Link></li>
                </ul>
            </nav>
        </div>
    </header>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
