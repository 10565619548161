/**
* SEO component that queries for data with
*  Gatsby's useStaticQuery React hook
*
* See: https://www.gatsbyjs.org/docs/use-static-query/
*/

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, keywords, title }) {
    const { site } = useStaticQuery(
        graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                }
            }
        }
        `
    )
    
    let metaTitle = title
    let titleTemplate = `%s – ${site.siteMetadata.title}`
    
    if (!metaTitle) {
        metaTitle = site.siteMetadata.title
        titleTemplate = `%s`
    } else if (metaTitle.includes(' – ')) {
        titleTemplate = `%s`
    }
    
    const metaDescription = description || site.siteMetadata.description
    
    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={metaTitle}
            titleTemplate={titleTemplate}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: metaTitle,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: metaTitle,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ]
            .concat(
                keywords.length > 0
                ? {
                    name: `keywords`,
                    content: keywords.join(`, `),
                }
                : []
            )
            .concat(meta)}
        />
    )
}

SEO.defaultProps = {
    lang: `de`,
    meta: [],
    keywords: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    keywords: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string.isRequired,
}

export default SEO
