import React from "react"
import PropTypes from "prop-types"

function TextBlock({ title, level, classes, children }) {
    
    const HeadingElement = `h${level}`
    let titleFormatted = title.replace(/\{(\/?)b\}/g,'<$1strong>')
    
    classes.unshift('text-block')
    
    return (
        <div className={classes.join(' ')}>
            <div className="text-block__content">
                <HeadingElement className="text-block__title" dangerouslySetInnerHTML={{ __html: titleFormatted }} />
                {children}
            </div>
        </div>
    )
}

TextBlock.propTypes = {
    title: PropTypes.string.isRequired,
    level: PropTypes.number,
    classes: PropTypes.array
}

TextBlock.defaultProps = {
    title: ``,
    level: 2,
    classes: []
}

export default TextBlock
